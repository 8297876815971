// 1024

.container.header-container{
    width: 100%;
    @media(min-width:1025px){
        width: 970px;
    };
    @media(min-width:1025px){
        width: 1170px;
    };
    
}

.header-minimal{
    height: 8rem;
    min-height: 8rem;
    border-bottom: 1px solid #dadada;
    background: linear-gradient(#b6d3e9 .5rem, transparent .5rem);
    padding-top: .5rem;
    @media (min-width: $screen-md-min){
        height: 11.2rem;
        min-height: 11.2rem;
        background: linear-gradient(#b6d3e9 5.1rem, transparent 2rem);
        padding-top: 0rem;
    }
    
}

.header-minimal .container, .header-minimal .row{
    height: 100%;
    width: 100%;
}

.header-minimal .container{
    @media (min-width: $screen-md-min){
        width: 970px;
    };
}

.header-minimal .logo.navbar-brand{
    height: 100%;
    margin-top: 0;
}

.partner-name{
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 0 0 1.5rem;
    color: $blue;
    @media(min-width: $screen-md-min){
        height: 5.1rem;
        font-size: 1.75rem;
    }
}
.line-items {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.line-items .line-item:not(:last-child) {
  border-bottom: 0.5px solid $blue-light;
}

.line-items .line-item:nth-of-type(2):not(:last-child) {
  border-bottom: 1px solid $blue;
}

.line-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 0;
}

.line-items .line-item:first-child {
  padding-top: 0;
}

.line-items .line-item:last-child {
  padding-bottom: 0;
}

.line-item h1,
.line-item h2,
.line-item h3,
.line-item h4,
.line-item h5,
.line-item h6,
.line-item p {
  margin-bottom: 0.25rem;
}

.line-item__title,
.line-item__price {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.line-item__price {
  font-family: $font-family-base;
  font-weight: 500;
  padding-left: 2rem;
}

.line-item__content,
.line-item__subcontent {
  display: flex;
  justify-content: space-between;
}

.line-item__content {
  color: $blue;
}

.line-item__content.bolder h1,
.line-item__content.bolder h2,
.line-item__content.bolder h3,
.line-item__content.bolder h4 .line-item__content.bolder h5,
.line-item__content.bolder h6,
.line-item__content.bolder p {
  font-weight: 600;
}

.line-item__subcontent p {
  font-family: $font-family-base;
  font-size: $font-size-small;
}

.line-item__subcontent .subcontent-para{
  width:86%;
}

@media (max-width: 768px) {
  .line-item__subcontent .subcontent-para{
    width:100% !important;
    text-align: justify !important;
  }
}
.todays-payment__container{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

p.todays-payment__intro{
    font-family: $font-family-base;
    font-size: $font-size-small;
    line-height: 18px;
}

div.todays-payment__error {
    background: $message-bg-error;
    padding: 1%;
    display: flex;
    margin-top: 1.5%;
    width: 100%;
}

#paymentIframeFirstBtn, #paymentIframeSecondBtn {
    display: flex;
    justify-content: center;
}

#startOverFirstBtn, #startOverSecondBtn {
    background: #fff;
    max-width: 18rem;
}

.todays-payment__error .error-sign {
    color: $red-error;
    margin: 1.2% 2% 0% 1%;
    font-size: 25px;
}

@media(max-width: $screen-sm-min){
    div.todays-payment__error {
        padding: 3%;
    }
    .todays-payment__error .error-sign {
        margin: 1.2% 3% 0% 1%;
    }
}
.final-actions-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    .continue-btn{
        max-width: 30rem;
        padding: .75rem;
        font-family: arial, helvetica, sans-serif;
    }
}
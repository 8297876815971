.installment-options {
    margin-top: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment-icon {
    color: #3A5A78;
    font-size: 3rem;
    display: flex;
}

.paymentstyle {
    width: 300px;
    margin-top: -40px;
    color: #3A5A78;
    font-family: $font-family-base;
    font-size:1.40rem;
    text-align: center;
    font-weight: 500;
}

.callstyle {
    color: #3A5A78;
    font-family: $font-family-base;
    font-size:1.40rem;
    text-align: center;
    font-weight: 500;
}
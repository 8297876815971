.processing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0.25rem;
    }
  }
  
  .processing-page-title {
    font-size: 2rem;
    color: #3D3D3D;
  }
  
  .icon.icon-spinner.icon-pulse {
    font-size: 2.25em;
    margin: 1rem;
    color: #3a5a78;
  }
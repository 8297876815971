@import url("https://s0.hfdstatic.com/higux/v3.3.48/css/screen.min.css");
@import "./scss/variables";
@import "./scss/accordian";
@import "./scss/line_items";
@import "./scss/Header";
@import "./scss/GenericErrorPage";
@import "./scss/TodaysPaymentModule";
@import "./scss/ChoosePaymentModule";
@import "./scss//PaymentUIPage";
@import './scss/CancelButton';
@import './scss/ProcessingPage';
@import "./scss/Creditcard";
@import "./scss/InstallmentOptionsModule";
@import "./scss/Footer";


.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App.generic-error, .App.loading{
  height: 100px;
}

.demo-app-container {
  padding-top: 3rem;
  display: flex;
  justify-content: center;
}

.app h1 {
  font-size: 40px;
  font-weight: 500;
}

.main-container {
  width: 100%;
  max-width: 734px;
  padding: 0 1rem;
  margin: 0 auto;
  @media (min-width: $screen-sm-min) {
    width: 70%;
  }
}

.section-wrapper {
  position: relative;
  border: 2px solid $blue-soft-border;
  border-radius: 8px;
  margin-top: 4rem;
  padding: 3.75rem;
  display: flex;
  flex-direction: column;
}

.section-title {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  @media (min-width: $screen-sm-min) {
    font-size: 40px;
  }
}

.section-subtitle {
  text-align: center;
  color: $blue;
}

.section-icon {
  color: $blue-soft-border;
  padding: 0rem 1.5rem;
  background: #f7f7f7;
  font-size: 4rem;
  height: 4rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  width: 8rem;
  top: -2rem;
}

.eqs-section-icon {
  color: $blue-soft-border;
  padding: 0rem 1.5rem;
  background: #fff;
  font-size: 4rem;
  height: 4rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  width: 8rem;
  top: -2rem;
}

.no-cardblock .section-icon {
}


.session-title {
  font-family: 'Gotham Medium', 'Gotham', sans-serif;
  font-weight: 350;
  font-style: normal;
  text-align: center;
  font-size: 42px;
  color: #3A5A78;
}
.session-content {
 p {
  font-family: 'Gotham Book', 'Gotham', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  color: #484848;
  margin-bottom: 10px;
  a {
    text-decoration: none;
    color: #2477BF;
    font-weight: 350;
  }
 }
}

.iframe-spinner-container{
  display: flex;
  justify-content: center;
}

.iframe-spinner-container .icon-spinner{
  position: relative;
  top: auto;
  left: auto;
}

.edit-button-display {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  float : right;
  height: 38px;
  width: 100%;
  background: #ddd;
  border: 1px solid #999;
  border-left-color: #3a5a78;
  padding: 0.25rem 0 .25rem .5rem;
  p{
    background-color: #dddd;
    margin-bottom :0%;
    width: 80%;
    float : left
  }
}



.is-ie .session-content{
  display: flex;
  flex-direction: column;
  height: 0;
  min-height: calc(100vh - 335px);
  justify-content: center;

  @media(min-width: 461px){
    min-height: calc(100vh - 318px);
  }
  @media(min-width: 768px){
    min-height: calc(100vh - 312px);
  }
  @media(min-width: 992px){
    min-height: calc(100vh - 345px);
  }
}
.choose-payment__container {
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.choose-payment__container .section-wrapper {
  padding-bottom: 0px;
  padding-top: 0px;
  border-color: $blue-light;
}

.section-wrapper.no-cardblock {
  padding: 3.75rem;
}

.payment-type__container {
  padding-top: 3.75rem;
}

.choose-payment__container .section-icon {
  color: $blue-soft-border;
}

.no-cardblock .payment-info__container {
  border-radius: 8px;
}

.payment-info__container {
  margin-left: -3.75rem;
  margin-right: -3.75rem;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.payment-type__container .radio {
  margin-bottom: 2rem;
}

.payment-type__text {
  font-size: 2rem;
  font-weight: 500;
}

label.radio-inline input[type="radio"] ~ .payment-type__text::before {
  font-size: 1.1em;
}

.payment-info__container .inner-accordian {
  padding: 3.75rem;
}

.payment-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  @media (min-width: $screen-md-min) {
    flex-direction: row;
  }
}

.payment-header .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  margin-right: 1rem;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
  @media (min-width: $screen-md-min) {
    font-size: 2.25rem;
  }
}

.payment-header__title {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.payment-header .card-type-display {
  margin: 1.5rem auto;
  @media (min-width: $screen-md-min) {
    margin: auto 0 auto auto;
  }
}

.payment-info__container .input-group-addon {
  padding: 5px 8px;
  font-size: 18px;
}

.card-type-display {
  display: inline-flex;

  .svg-icon {
    font-size: 1px;
  }
  .svg-icon:nth-child(n + 2) {
    margin-left: 1rem;
  }
}

.expiration-date-container .select-container {
  float: unset;
  margin-bottom: 0;
}

.expiration-date-container {
  display: flex;
  flex-direction: column;
}

.expiration-date-container .control-label {
  width: 100%;
  font-weight: 500;
}

.month-year-inputs {
  display: flex;
  margin-bottom: 0;
}

.month-year-inputs .expiration-month-container {
  max-width: 250px;
  margin-right: 2rem;
}

.month-year-inputs .expiration-year-container {
  max-width: 100px;
}

.credit-card-input .help-block {
  font-weight: 100;
}

.credit-card-input input {
  width: 100%;
}

.bank-info-form .toggle-container {
  height: auto;
}

.account-form-groups {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  width: 100%;

  .form-group {
    width: 100%;
  }

  .errors-wrapper {
    width: 100%;
    display: flex;
  }

  @media (min-width: $screen-md-min) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-group {
      width: calc(50% - 1rem);
      margin-bottom: 0;
    }
  }
}

.control-label .tooltip-soft-text {
  margin-left: 0.5rem;
}

.account-type-form-group {
  margin-bottom: 1.25em;
}

.banking-info__input-container {
  margin-bottom: 0;
}

.banking-info__input-container .icon:hover {
  cursor: pointer;
}

.bank-only-container {
  .btn-group-banking-type .btn {
    width: 50%;
  }
}

.bank-only-account-info-container {
  display: flex;
  flex-direction: column;

  .bank-display-name p {
    margin-bottom: 0;
  }

  @media (min-width: $screen-md-min) {
    flex-direction: row;
    justify-content: space-between;

    .account-form-groups {
      flex-direction: column;
      width: calc(50% - 1.5rem);
      justify-content: flex-start;
    }

    .account-form-groups .form-group {
      width: 100%;
    }

    .account-form-groups .form-group:nth-child(2) {
      margin-top: 1.25em;
    }
  }
}

.account-info-image {
  width: 100%;
  img {
    width: 100%;
    display: flex;
  }
  @media (min-width: $screen-md-min) {
    width: calc(50% - 1.5rem);
  }
}

.modal-account-types-container {
  padding: 4rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 72rem;
  }
}

.modal-account-type {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 30rem;
  @media (min-width: $screen-sm-min) {
    max-width: 50%;
    width: calc(50% - 2rem);
  }
  @media (min-width: $screen-md-min) {
    width: calc(50% - 4rem);
  }
}

.modal-account-type__title {
  font-size: 1.75rem;
}

.modal-account-type__img {
  width: 100%;
  overflow: hidden;
  img {
    width: 107%;
    margin-left: -3%;
  }
}

#accountNumbersModal {
  padding: 10px 10px 0;
  height: 100vh;
}

#accountNumbersModal .modal-dialog {
  padding-left: 0;
  padding-right: 0;
  overflow-y: auto;
  margin: 0;
  height: 100%;
  @media (min-width: $screen-sm-min) {
    margin: 30px auto;
    height: unset;
  }
}

#accountNumbersModal .modal-content {
  margin-bottom: 10px;
}

#accountNumbersModal .modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

#accountNumbersModal .modal-footer .btn {
  width: 100%;
  max-width: 18em;
}

.errors-wrapper {
  display: flex;

  .form-control-feedback {
    position: relative;
  }
}

.has-error .btn-group-banking-type .btn,
.credit-card-error {
  color: #b83b2f;
  border-color: #b83b2f;
}

.bank-display-name .icon.icon-spinner.icon-pulse {
  color: #3a5a78;
}
.auth-msg-heading{
  margin-top: 1rem;
  margin-bottom:0%;
  font-size: 13px;
}
.auth-msg-body{
  font-size: 12px;
}

.auth-msg-line-break{
  white-space: pre-line;
  max-height: 10rem;
  overflow-y:scroll;
  text-align: justify !important;
}

.fraud-warning-msg-heading {
  font-size: 16px;
  font-weight: 500;
}

.fraud-warning-msg-padding {
  margin-top:1em;
  text-align: justify !important;
}

.checkbox-alignment {
  margin-top: 2em;
}

.fraud-warning-text-msg {
  font-size: 14px;
  margin-top: 2em;
}

.payment-info_container_starting_line{
  height: 4px;
  width: 88%;
  background-color: $linus-blanket !important;
  margin-top: 0;
  margin-bottom: 0;
}

.payment-info__container{
  .btn-group-banking-type .btn.btn-secondary {
    width: 50%;
  }
}

.choose-bank-container {
  display: flex;
  flex-direction: column;

  .bank-display-name p {
    margin-bottom: 0;
  }

  @media (min-width: $screen-md-min) {
    flex-direction: row;
    justify-content: space-between;

    .account-form-groups {
      flex-direction: column;
      width: calc(50% - 1.5rem);
      justify-content: flex-start;
    }

    .account-form-groups .form-group {
      width: 100%;
    }

    .account-form-groups .form-group:nth-child(2) {
      margin-top: 1.25em;
    }
  }
}


@media (max-width: 768px) {
  .auth-msg-line-break{
    margin-top: 20px !important;
  }
  .textJustifyDivClass{
    margin-left: 2% !important;
    width: 95% !important;
    text-align: justify !important;
  }
  .labelRadioClass{
    margin-right: 0px !important;
  }
}

.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip:rect(0, 0, 0, 0);
  border: 0;
}

.color-contract{
  color: #585858 !important;
}

.creditCardEditButton{
  color: #3a5a78 !important;
}

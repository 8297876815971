.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0rem;
  p {
    margin-bottom: 0.25rem;
  }
}

.error-page-title {
  font-size: 5rem;
}

.start-over-link{
  text-decoration: none;
}

.loadingSpinnerCenter {
  color: #3a5a78;
}

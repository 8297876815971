// Colors
$black: #000;
$blue: #3a5a78;
$blue-active: #4E79A2;
$blue-light: #b6d3e9;
$blue-soft-bar: #f2f7fB;
$blue-soft-border: #5396b5;
$linus-blanket: #eaf2f8;
$blue-hover: #68b5e4;
$blue-link: #1570be;
$blue-link-soft: #2477BF;
$blue-light-table-hover: #deecf7;
$blue-light-table-column: #e3f3ff;
$blue-progress: #68b5e4;
$bluegreen: #068f96;
$gray: #484848;
$gray-dark: #3d3d3d;
$gray-disabled: #757575;
$gray-pewter: #6B6B6B;
$gray-placeholder: #757575;
$gray-background: #f7f7f7;
$gray-silver: #aaaaaa;
$gray-tab-light: #e5e5e5;
$gray-light: #dadada;
$gray-lighter: #ededed;
$gray-lighter-table-bg: #f9f9f9;;
$gray-medium: #888;
$gray-hover: #fafafa;
$gray-tile: #757575;
$gray-body: #333;
$green: #118654;
$green-active: #13a060;
$violet: #5F1796;
$purple: #4d4369;
$plum: #847094;
$red: #822b2f;
$red-error: #b83b2f;
$red-salmon: #c0504d;
$white: #fff;
$yellow: #efc21b;
$yellow-warning: #e87502;
$orange: #f7941f;
$gold: #f9cf67;
$outline-focus:#2188FF;
$message-bg-info:#EAF2F9;
$message-bg-error:#F9EDEC;
$message-bg-warn:#FDF3E8;
$message-bg-success:#EAF4F0;

// Shadows
$shadow-deep: rgba(0, 0, 0, 0.75);
$shadow-medium-deep: rgba(0, 0, 0, 0.5);
$shadow-shallow: rgba(0, 0, 0, 0.05);
$shadow-medium: rgba(0, 0, 0, 0.175);
$shadow-icons: .03em .03em .09em #666;
$shadow-card: rgba(0, 0, 0, 0.14);
$shadow-button-focus: rgba(200,225,255,1);

// Border Radiuses
$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;
// Accordions Variables
$panel-header-bg: $gray-lighter;
$panel-header-bg-active: $blue;
// Fonts
$font-family-base: "HCo Gotham", arial, sans-serif;
$font-family-title: "HCo Gotham", arial, sans-serif;
$font-family-paragraph: "HCo Gotham SSm", arial, sans-serif;
$font-family-sup: "arial, helvetica, sans-serif";
$font-size-base: 16px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~20px
$font-size-small: ceil(($font-size-base * 0.875)) !default; // ~14px
$font-size-h1: floor(($font-size-base * 1.625)); // ~26px
$font-size-h2: floor(($font-size-base * 1.375)); // ~22px
$font-size-h3: ceil(($font-size-base * 1.25)); // ~20px
$font-size-h4: ceil(($font-size-base * 1.125)); // ~18px
$font-size-h5: $font-size-base !default;
$baseline-px: 16 !default;
// Breakpoint Variables & Shorthand Media Queries
$screen-xs-min: 565px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
.cancel-btn-container{
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    @media(min-width: $screen-md-min){
        margin-bottom: 10rem;
    }
}

.btn.cancel-btn{
    color: $blue-link;
    max-width: 30rem;
    width: 100%;
    display: block;
    margin-top: 2rem;
}
iframe {
  background-color: #fff;
  border: 1px solid #3a5a78;
  width: 300%;
  height: 39px;
  margin-left: 2px;
  z-index: 99;
  top: 0px;
  position: absolute;
  transform: scale(1.2) translateX(7.65%);
}

.wrapper-iframe {
  overflow: hidden;
  width: calc (100vw * 1.6);
  height: 38px;
  width: 100%;
  position: relative;
  border: 1px solid #3a5a78;
}

em {
  position: absolute;
  left: 45%;
  top: -60px;
  z-index: 999;
}
